import logo from './logo.svg'
import './App.css'
import AppRoutes, { extractNestedRoutes } from './routes'
import {
  createBrowserRouter,
  RouterProvider,
  BrowserRouter as Router,
  Routes,
  Route,
  useNavigate
} from 'react-router-dom'
import { Button, Spinner, Text, VStack } from '@chakra-ui/react'
import { useSelector, useDispatch } from 'react-redux'
import { setUser } from './redux/slices/userSlice'
import { useEffect, useState } from 'react'
import { AppLayout } from './screens/mainApp/appLayout'
import { setAppState } from './redux/slices/appSlice'
import SharedComponents from 'shared-components'
import * as FirebaseFunctions from 'firebase/functions'
import axios from 'axios'
import React from 'react'
import AuthLayout from './screens/auth/layout'

function AuthRoutes () {
  const _routes = extractNestedRoutes(AppRoutes.Auth)
  return createBrowserRouter(_routes)
}
function MainAppRoutes () {
  const _routes = extractNestedRoutes(AppRoutes.MainApp)
  return createBrowserRouter(_routes)
}

function App () {
  const userState = useSelector(state => state.user)
  const adminAppId = useSelector(state => state.app.adminAppId)
  const [isFetchingAppId, setIsFetchingAppId] = useState(false)
  const dispatch = useDispatch()

  useEffect(() => {
    const findAppId = async () => {
      setIsFetchingAppId(true)
      const function_url = () => {
        if (!process.env.NODE_ENV || process.env.NODE_ENV === 'development') {
          return process.env.REACT_APP_FUNCTIONS_URL_DEV
        } else return process.env.REACT_APP_FUNCTIONS_URL_PROD
      }
      try {
        const result = await axios.get(`${function_url()}getAppId`)
        dispatch(
          setAppState({
            key: 'adminAppId',
            value: result.data.appId
          })
        )
        // dispatch(setCustomers(result.data))
      } catch (ex) {
        console.warn('CloudFunction error:', ex)
      }
      setIsFetchingAppId(false)
    }
    findAppId()
  }, [])

  const RenderScreens = () => {
    if (isFetchingAppId) {
      return <Spinner size='md' />
    }
    if (adminAppId) {
      if (userState.id) {
        if (userState.id === adminAppId) {
          return (
            <Router>
              <Routes>
                <Route path='*' element={<NotFound />} />
                <Route path='/' element={<AppLayout />}>
                  {MainAppRoutes().routes.map(_route => (
                    <Route key={`routeKey_${_route.path}`} {..._route} />
                  ))}
                </Route>
              </Routes>
            </Router>
          )
        } else {
          return (
            <Router>
              <Routes>
                <Route path='*' element={<NotFound />} />
                <Route
                  path='/'
                  element={
                    <VStack w='full' h='full' k>
                      <Text fontSize={'xx-large'}>
                        Seems like you're trying to log in to another account.
                        Please refresh this page.
                      </Text>
                    </VStack>
                  }
                ></Route>
              </Routes>
            </Router>
          )
        }
      } else {
        return (
          <Router>
            <Routes>
              <Route path='*' element={<NotFound />} />
              <Route path='/' element={<AuthLayout />}>
                {AuthRoutes().routes.map(_route => (
                  <Route key={`routeKey_${_route.path}`} {..._route} />
                ))}
              </Route>
            </Routes>
          </Router>
        )
      }
    } else {
      return (
        <Router>
          <Routes>
            <Route path='*' element={<NotFound />} />
            <Route
              path='/'
              element={
                <VStack w='full' h='full' k>
                  <Text fontSize={'xx-large'}>
                    Seems like this app is not configured right
                  </Text>
                </VStack>
              }
            ></Route>
          </Routes>
        </Router>
      )
    }
  }

  return (
    <VStack h='full' w='full'>
      <RenderScreens />
    </VStack>
  )
}

export default App

const NotFound = () => {
  const navigate = useNavigate()
  useEffect(() => {
    navigate('/')
  }, [])
  return <></>
}
