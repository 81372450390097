import { useEffect, useState } from 'react'
import Routes, { extractNestedRoutes } from '../../routes'
import {
  Box,
  Button,
  Grid,
  GridItem,
  HStack,
  Image,
  Modal,
  ModalBody,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Spinner,
  Text,
  useToast,
  VStack
} from '@chakra-ui/react'
import Logo from '../../assets/images/logo-horizontal.png'
import {
  useNavigate,
  Outlet,
  useLocation,
  useSearchParams
} from 'react-router-dom'
import SharedComponents from 'shared-components'
import { useDispatch, useSelector } from 'react-redux'
import { setAppState } from '../../redux/slices/appSlice'
import { setUser, setUserProps } from '../../redux/slices/userSlice'
import { FaArrowRight } from 'react-icons/fa6'
import * as FirebaseFunctions from 'firebase/functions'
import { signOut } from 'firebase/auth'

export function AppLayout () {
  const { navbarExpanded } = useSelector(state => state.app)
  const dispatch = useDispatch()
  const user = useSelector(state => state.user)
  const stripeConfirmed = useSelector(state => state.app.stripeConfirmed)
  const location = useLocation()
  const navigate = useNavigate()
  const firebaseContext = SharedComponents.Firebase.context()

  function MainAppRoutes () {
    const _routes = extractNestedRoutes(Routes.MainApp)
    return { routes: _routes }
  }

  useEffect(() => {
    if (location.pathname === '/') {
      console.log('Redirecting to home...')
      navigate('/my-music')
    }
  }, [])

  function triggerNavbar () {
    dispatch(
      setAppState({
        key: 'navbarExpanded',
        value: !navbarExpanded
      })
    )
  }

  async function performLogOut () {
    try {
      await signOut(firebaseContext.auth)
      dispatch(setAppState({ key: 'stripeConfirmed', value: null }))
      dispatch(setUser(null))
    } catch (ex) {
      console.log('Could not log out user:', ex)
    }
  }

  async function confirmStripe (stripeAccId) {
    console.log('Confirming stripe...')
    dispatch(
      setAppState({
        key: 'stripeConfirmed',
        value: true
      })
    )
    dispatch(setUserProps({ key: 'stripeAccountId', value: stripeAccId }))
    const url = new URL(window.location.href)
    navigate(url.pathname)
  }

  return (
    <Grid
      w='full'
      h='full'
      gridTemplateColumns={'1fr'}
      gridTemplateRows={'70px 1fr'}
      gridColumnGap={'0px'}
      gridRowGap={'0px'}
      bg='white'
      overflow={'hidden'}
    >
      {/* {!stripeConfirmed && (
        <GridItem>
          <StripeSetupComponent onComplete={confirmStripe} />
        </GridItem>
      )} */}
      <GridItem
        w='full'
        h='full'
        alignContent={'center'}
        borderBottom={'1px solid #ebebeb'}
        px='4'
        pr='2'
      >
        <HStack w='full' justify={'space-between'}>
          <Image src={Logo} w='200px' objectFit={'contain'} />
          <HStack justify={'flex-end'} w='max-content' h='full' py='2' px='4'>
            <HStack w='max-content' h='full' spacing={'4'}>
              <VStack
                w='max-content'
                h='full'
                spacing={'0'}
                alignItems={'flex-start'}
                justify={'center'}
              >
                <Text fontSize={'12px'} fontWeight={'500'}>
                  Logged in as,
                </Text>
                <Text fontSize={'14px'} fontWeight={'500'}>
                  {user.name}
                </Text>
              </VStack>
              <Box w='20px' />
              <SharedComponents.AppComponent.LogOutButton
                onLogOut={performLogOut}
              />
            </HStack>
          </HStack>
        </HStack>
      </GridItem>
      <GridItem w='full' h='full' overflow={'hidden'}>
        <Grid
          transition={'all 300ms'}
          gridTemplateColumns={navbarExpanded ? '240px 1fr' : '65px 1fr'}
          h='100%'
        >
          <GridItem
            borderRight={navbarExpanded ? '1px solid #ebebeb' : 'none'}
            boxShadow={
              !navbarExpanded ? '0px 0px 20px 0px rgba(19,51,133,0.2)' : 'none'
            }
            py='5'
            px={navbarExpanded ? '4' : '0'}
          >
            <VStack w='full' h='full' justify={'space-between'}>
              <VStack w='full' h='max-content'>
                {MainAppRoutes().routes.map(route => (
                  <SharedComponents.AppComponent.NavItem
                    key={`navitemKey_${route.path}`}
                    navbarExpanded={navbarExpanded}
                    {...route}
                  />
                ))}
              </VStack>
              <SharedComponents.AppComponent.NavCollapseBtn
                onCollapseExpand={triggerNavbar}
                navbarExpanded={navbarExpanded}
              />
            </VStack>
          </GridItem>
          <GridItem w='full' py='5' px='5' pl='7' h='full' overflow={'scroll'}>
            <Outlet />
          </GridItem>
        </Grid>
      </GridItem>
    </Grid>
  )
}

const StripeSetupComponent = ({ onComplete }) => {
  const dispatch = useDispatch()
  const firebaseContext = SharedComponents.Firebase.context()
  const [searchParams] = useSearchParams()
  const [isStripeBusy, setIsStripeBusy] = useState(false)
  const userState = useSelector(state => state.user)
  const [stateType, setStateType] = useState('initial')
  const toast = useToast()
  const stripeAccountId =
    searchParams.get('confirmStripeAccountId') || userState.stripeAccountId

  const [stripeLink, setStripeLink] = useState()

  useEffect(() => {
    if (stripeAccountId) {
      setStateType('health-check')
    }
  }, [])

  const Initial = () => {
    async function setUpStripe () {
      setIsStripeBusy(true)
      console.log('User state befoe stripe is:', userState)
      const _stripeAcc = FirebaseFunctions.httpsCallable(
        firebaseContext.functions,
        'createStripeAccount'
      )
      try {
        const result = await _stripeAcc({
          refreshUrl: window.location.href,
          returnUrl: window.location.href
        })
        console.log('Stripe account result:', result.data)
        if (result.data.setupUrl && result.data.accountId) {
          try {
            await firebaseContext.addDocument({
              path: `profile/${userState.id}`,
              data: {
                ...userState,
                stripeAccountId: result.data.accountId
              }
            })
            window.open(result.data.setupUrl, '_self')
          } catch (ex) {
            setIsStripeBusy(false)
            console.log('Could not update profile with stripeId:', ex)
          }
        }
        // dispatch(setCustomers(result.data))
      } catch (ex) {
        console.warn('CloudFunction error:', ex)
        setIsStripeBusy(false)
      }
    }

    return (
      <>
        {!isStripeBusy && (
          <Text fontSize={'14px'} fontWeight={'bold'} color='white' pb='1'>
            You need to set up your Stripe account first to start accepting
            payments.
          </Text>
        )}
        <Button
          transition={'all 300ms'}
          h='max-content'
          variant={'ghost'}
          w='max-content'
          rightIcon={<FaArrowRight />}
          isLoading={isStripeBusy}
          loadingText='Please Wait...'
          px='0'
          py='0'
          color='white'
          borderBottom={'1px solid transparent'}
          fontSize={'13px'}
          borderRadius={'0'}
          _hover={{
            bg: 'transparent',
            borderBottom: '1px solid white'
          }}
          onClick={setUpStripe}
        >
          Set Up Now
        </Button>
      </>
    )
  }

  const StatusCheck = () => {
    useEffect(() => {
      getStripeHealth()
    }, [])

    async function getStripeHealth () {
      const _stripeAcc = FirebaseFunctions.httpsCallable(
        firebaseContext.functions,
        'getStripeAccountStatus'
      )
      try {
        const appUrl = window.location.href.split('?')[0]
        const result = await _stripeAcc({
          accountId: stripeAccountId,
          refreshUrl: appUrl,
          returnUrl: appUrl
        })
        if (!result.data.complete) {
          console.log('Stripe account needs more verification:', result.data)
          // window.open(result.data.setupUrl, '_self')
          setStripeLink(result.data.setupUrl)
          setStateType('need-info')
        } else {
          console.log('Stripe account is safe to use now!')
          onComplete(stripeAccountId)
          // dispatch(
          //   setUserProps({ key: 'stripeAccountId', value: _stripeAccoutId })
          // )
        }
      } catch (ex) {
        console.warn('CloudFunction error:', ex)
      }
    }
    return (
      <>
        <Spinner color='white' />
        <Text fontSize={'14px'} fontWeight={'bold'} color='white' pb='1'>
          Checking your Stripe health...
        </Text>
      </>
    )
  }

  const NeedInfo = () => {
    async function loginToStripe () {
      window.open(stripeLink, '_self')
    }

    return (
      <>
        {!isStripeBusy && (
          <Text fontSize={'14px'} fontWeight={'bold'} color='white' pb='1'>
            Your Stripe account needs more information, to accept payments.
          </Text>
        )}
        <Button
          transition={'all 300ms'}
          h='max-content'
          variant={'ghost'}
          w='max-content'
          rightIcon={<FaArrowRight />}
          isLoading={isStripeBusy}
          loadingText='Please Wait...'
          px='0'
          py='0'
          color='white'
          borderBottom={'1px solid transparent'}
          fontSize={'13px'}
          borderRadius={'0'}
          _hover={{
            bg: 'transparent',
            borderBottom: '1px solid white'
          }}
          onClick={loginToStripe}
        >
          Complete My Account
        </Button>
      </>
    )
  }

  function getBg () {
    if (stateType === 'initial' || stateType === 'need-info') {
      return 'red.500'
    } else if (stateType === 'health-check') {
      return 'orange.600'
    } else {
      return 'green'
    }
  }

  return (
    <HStack w='full' justify={'center'} bg={getBg()} py='1'>
      {stateType === 'initial' && <Initial />}
      {stateType === 'health-check' && <StatusCheck />}
      {stateType === 'need-info' && <NeedInfo />}
    </HStack>
  )
}
