import { createSlice } from '@reduxjs/toolkit'

const initialState = []

export const subscriptionSlice = createSlice({
  name: 'subscriptions',
  initialState,
  reducers: {
    setAllSubscriptions: (state, action) => {
      return action.payload
    },
    setSubscription: (state, action) => {
      if (state && state.length > 0) {
        console.log('Found payload:', action.payload)
        // console.log('State subs:', state)
        const spread = [...state]
        const _subscription = spread.find(x => x.id === action.payload.id)

        // console.log('Found existing subs:', _subscription)
        // Found existing data
        if (_subscription) {
          const _subscriptionIndex = spread.findIndex(x => x.id === action.payload.id)
          spread[_subscriptionIndex] = { ..._subscription, ...action.payload }
          console.log('Updated state needs to be:', spread[_subscriptionIndex])
          return spread
        } else {
          spread.unshift(action.payload)
          return spread
        }
      } else {
        return [action.payload]
      }
    }
  }
})

// Action creators are generated for each case reducer function
export const { setAllSubscriptions, setSubscription } = subscriptionSlice.actions

export default subscriptionSlice.reducer
