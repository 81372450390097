import {
  HStack,
  InputGroup,
  InputRightAddon,
  InputRightElement,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Spinner,
  Text,
  Tooltip,
  useToast,
  VStack
} from '@chakra-ui/react'
import PageLayout from '../pageLayout'
import SharedComponents from 'shared-components'
import { useEffect, useRef, useState } from 'react'
import axios from 'axios'
import { useDispatch, useSelector } from 'react-redux'
import { setUserProps } from '../../../redux/slices/userSlice'

const TicketMaster_API_KEY = 'EdUt9Pa8LrpEsnYC5XbpRArZkD1eUgWD'

export default () => {
  const [searchVal, setSearchVal] = useState('')
  const [tcmResult, setTcmResult] = useState([])
  const [isFetching, setIsFetching] = useState(false)
  const userTicketMasterId = useSelector(state => state.user.ticketMasterId)

  async function performSearch (e) {
    if (e.key === 'Enter') {
      setIsFetching(true)
      try {
        const result = await axios.get(
          `https://app.ticketmaster.com/discovery/v2/attractions?apikey=${TicketMaster_API_KEY}&keyword=${searchVal}`
        )
        if (result.data._embedded?.attractions) {
          //   console.log('Found result:', result.data._embedded?.attractions)
          //   console.log('Now filtering...')
          const musicians = result.data._embedded.attractions.filter(x => {
            if (x.classifications) {
              return (
                x.classifications.find(
                  classification => classification.primary == true
                )?.subType?.name === 'Musician'
              )
            }
          })
          //   console.log(
          //     result.data._embedded?.attractions[0].classifications[0].subType
          //       .name
          //   )
          console.log('Final result:', musicians)
          setTcmResult(musicians)
        }
      } catch (ex) {
        console.log('Error is:', ex)
      }
      setIsFetching(false)
    }
  }
  return (
    <PageLayout>
      {!userTicketMasterId && (
        <>
          <SharedComponents.AppComponent.LabeledInput
            label='Search your ticket master profile'
            placeholder='Search here...'
            inputProps={{
              onKeyUp: performSearch
            }}
            onChange={val => setSearchVal(val)}
            value={searchVal}
          />
          <HStack w='full' flexWrap={'wrap'}>
            {isFetching && <Spinner />}
            {tcmResult?.map(item => (
              <ArtistComponent data={item} key={`tcm_result_${item.id}`} />
            ))}
          </HStack>
        </>
      )}
      {userTicketMasterId && <TicketMasterProfile id={userTicketMasterId} />} //
      Uncomment this line
    </PageLayout>
  )
}

const ArtistComponent = ({ data, onSelectionConfirm = () => {} }) => {
  const firebaseContext = SharedComponents.Firebase.context()
  const userState = useSelector(state => state.user)
  const [isBusy, setIsBusy] = useState(false)
  const confirmModalRef = useRef()
  const toast = useToast()
  const dispatch = useDispatch()
  function setImage () {
    return data.images?.find(x => x.ratio === '4_3')?.url
  }

  function showConfirmModal () {
    confirmModalRef.current?.triggerModal()
  }

  async function confirmSelection () {
    setIsBusy(true)
    try {
      await firebaseContext.addDocument({
        path: `profile/${userState.id}`,
        data: {
          ...userState,
          ticketMasterId: data.id
        }
      })
      confirmModalRef.current?.triggerModal()
      onSelectionConfirm()
      dispatch(setUserProps({ key: 'ticketMasterId', value: data.id }))
    } catch (ex) {
      setIsBusy(false)
      toast({
        title: 'Could not update',
        description: 'Could not set your TicketMaster profile. Try again!',
        status: 'error',
        isClosable: true
      })
    }
  }

  const classification = data.classifications[0]
  return (
    <VStack
      transition={'all 300ms'}
      bg='gray.100'
      px='1'
      py='1'
      align={'flex-start'}
      borderRadius={'md'}
      w='130px'
      _hover={{
        bg: 'black',
        color: 'white'
      }}
      cursor={'pointer'}
      onClick={showConfirmModal}
    >
      <SharedComponents.AppComponent.Modal
        ref={confirmModalRef}
        onProceed={confirmSelection}
        isProceedBusy={isBusy}
        size='md'
        proceedBtnLabel='Confirm'
      >
        <VStack>
          <Text fontWeight={'semibold'}>
            Are you sure this your correct TicketMaster profile? This will be connected to your account.
          </Text>
        </VStack>
      </SharedComponents.AppComponent.Modal>
      <SharedComponents.AppComponent.LazyImage
        h='115px'
        w='full'
        src={setImage()}
      />
      <VStack w='full' px='2' align={'flex-start'} spacing={'0'}>
        <Text
          fontSize={'14px'}
          fontWeight={'semibold'}
          w='100px'
          textOverflow={'ellipsis'}
          overflow={'hidden'}
          whiteSpace={'nowrap'}
        >
          {data.name}
        </Text>
        <Text fontSize={'14px'}>Musician</Text>
        <Text fontSize={'12px'}>
          {`${classification.segment?.name} / ${classification.genre?.name} / ${classification.subGenre?.name}`}
        </Text>
      </VStack>
    </VStack>
  )
}

const TicketMasterProfile = ({ id }) => {
  const firebaseContext = SharedComponents.Firebase.context()
  const userState = useSelector(state => state.user)
  const [data, setData] = useState(null)
  const [isBusy, setIsBusy] = useState(false)
  const confirmModalRef = useRef()
  const toast = useToast()
  const dispatch = useDispatch()

  useEffect(() => {
    if (id) {
      getArtist()
    }
  }, [id])

  async function getArtist () {
    try {
      const result = await axios.get(
        `https://app.ticketmaster.com/discovery/v2/attractions/${id}?apikey=${TicketMaster_API_KEY}`
      )
      setData(result.data)
      console.log('Found artist:', result.data)
    } catch (ex) {
      console.log('Could not fetch artist:', ex)
    }
  }
  function setImage () {
    return data?.images?.find(x => x.ratio === '4_3')?.url
  }

  function showConfirmModal () {
    confirmModalRef.current?.triggerModal()
  }

  async function confirmSelection () {
    setIsBusy(true)
    try {
      await firebaseContext.addDocument({
        path: `profile/${userState.id}`,
        data: {
          ...userState,
          ticketMasterId: null
        }
      })
      confirmModalRef.current?.triggerModal()
      // onSelectionConfirm()
      dispatch(setUserProps({ key: 'ticketMasterId', value: null }))
    } catch (ex) {
      setIsBusy(false)
      toast({
        title: 'Could not update',
        description: 'Could not set your TicketMaster profile. Try again!',
        status: 'error',
        isClosable: true
      })
    }
  }

  const classification = data?.classifications[0]
  return !data ? (
    <Spinner />
  ) : (
    <Tooltip placement='right' hasArrow label='Click to disconnect'>
      <VStack
        transition={'all 300ms'}
        bg='gray.100'
        px='1'
        py='1'
        align={'flex-start'}
        borderRadius={'md'}
        w='130px'
        _hover={{
          bg: 'black',
          color: 'white'
        }}
        cursor={'pointer'}
        onClick={showConfirmModal}
      >
        <SharedComponents.AppComponent.Modal
          ref={confirmModalRef}
          onProceed={confirmSelection}
          isProceedBusy={isBusy}
          size='md'
          proceedBtnLabel='Confirm'
        >
          <VStack>
            <Text fontWeight={'semibold'}>
              Are you sure you want to disconnect this TicketMaster profile?
            </Text>
          </VStack>
        </SharedComponents.AppComponent.Modal>
        <SharedComponents.AppComponent.LazyImage
          h='115px'
          w='full'
          src={setImage()}
        />
        <VStack w='full' px='2' align={'flex-start'} spacing={'0'}>
          <Text
            fontSize={'14px'}
            fontWeight={'semibold'}
            w='100px'
            textOverflow={'ellipsis'}
            overflow={'hidden'}
            whiteSpace={'nowrap'}
          >
            {data?.name}
          </Text>
          <Text fontSize={'14px'}>Musician</Text>
          <Text fontSize={'12px'}>
            {`${classification?.segment?.name} / ${classification?.genre?.name} / ${classification?.subGenre?.name}`}
          </Text>
        </VStack>
      </VStack>
    </Tooltip>
  )
}
