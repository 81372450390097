import {
  Badge,
  Box,
  Button,
  Checkbox,
  HStack,
  IconButton,
  Image,
  Radio,
  RadioGroup,
  Text,
  Tooltip,
  useToast,
  VStack,
  Select as ChakraSelect,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  ModalFooter
} from '@chakra-ui/react'
import SharedComponents from 'shared-components'
import PageLayout from '../pageLayout'
import { useEffect, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { setAllMusic, setMusic } from '../../../redux/slices/musicSlice'
import moment from 'moment'
import { GoEye, GoPencil, GoPlay, GoTrash } from 'react-icons/go'
import { LuMusic2 } from 'react-icons/lu'
import { BsMusicNoteList } from 'react-icons/bs'
import * as FirebaseFunctions from 'firebase/functions'
import { setAllSubscriptions } from '../../../redux/slices/subscriptionSlice'
import { FaArrowRight, FaPlay } from 'react-icons/fa6'
import { MusicComponent } from './components'
import Select from 'react-select'
import { collection } from 'firebase/firestore'

export default () => {
  const newItemModalRef = useRef()
  const musicUploadBtnRef = useRef()
  const pictureUploadBtnRef = useRef()
  const musicState = useSelector(state => state.music)
  const subscriptionState = useSelector(state => state.subscription)
  const analyticsViewer = useRef()

  const initState = {
    name: '',
    createdOn: new Date().toISOString(),
    type: 'music',
    streamCount: 0,
    imageUrl: '',
    baseUrl: '',
    priceId: null,
    chargeType: 'onstream',
    status: 'available'
  }
  const [mediaData, setMediaData] = useState(initState)
  const [editMode, setEditMode] = useState(false)
  const [profilePictureFile, setProfilePictureFile] = useState()
  const [mediaFile, setMediaFile] = useState()
  const dispatch = useDispatch()
  const firebaseContext = SharedComponents.Firebase.context()
  const toast = useToast()
  const playerRef = useRef()
  const userId = useSelector(state => state.user.id)
  const ColumnDef = [
    {
      key: 'imageUrl',
      renderCell: ({ rowData, rowIndex, cellValue, onCellValueUpdate }) => (
        <SharedComponents.AppComponent.LazyImage
          h='full'
          w='40px'
          objectFit={'contain'}
          src={cellValue}
          borderRadius={'sm'}
        />
      ),
      allowSort: false,
      columnProps: {
        width: '40px'
      }
    },
    {
      label: 'Name',
      key: 'name'
    },
    {
      label: 'Type',
      key: 'type',
      renderCell: ({ rowData, rowIndex, cellValue, onCellValueUpdate }) => (
        <HStack textTransform={'capitalize'}>
          {cellValue === 'music' ? <LuMusic2 /> : <BsMusicNoteList />}
          <Text>{cellValue}</Text>
        </HStack>
      )
    },
    {
      label: 'Media',
      renderCell: ({ rowData, rowIndex, cellValue, onCellValueUpdate }) =>
        rowData?.type === 'music' ? (
          <Button
            size='xs'
            colorScheme='red'
            leftIcon={<FaPlay />}
            onClick={() => playMusic(rowData)}
          >
            Play
          </Button>
        ) : (
          <Button
            role='group'
            transition={'all 300ms'}
            size='xs'
            colorScheme='ghost'
            color='black'
            borderBottom={'1px solid #fff'}
            borderRadius={'0'}
            px='0'
            rightIcon={
              <Box
                transition={'all 300ms'}
                transform={'translateX(-5px)'}
                opacity={'0'}
                _groupHover={{
                  opacity: '1',
                  transform: 'translateX(0px)'
                }}
              >
                <FaArrowRight />
              </Box>
            }
            _hover={{
              borderBottom: '1px solid black'
            }}
          >
            See All Music
          </Button>
        )
    },
    {
      label: 'Analytics',
      renderCell: ({ rowData }) =>
        rowData.type === 'music' && (
          <Button size='xs' onClick={() => showAnalytics(rowData)}>
            Show Analytics
          </Button>
        )
    },
    {
      label: 'Uploaded On',
      key: 'createdOn',
      renderCell: ({ rowData, rowIndex, cellValue, onCellValueUpdate }) => (
        <Text>{moment(cellValue).format('MMM Do YYYY, h:mm:ss a')}</Text>
      )
    },
    {
      label: 'Status',
      key: 'status',
      renderCell: ({ rowData, rowIndex, cellValue, onCellValueUpdate }) => (
        <Tooltip
          hasArrow
          borderRadius={'md'}
          px='3'
          py='2'
          fontSize={'12px'}
          fontWeight={'semibold'}
          lineHeight={'1rem'}
          w='200px'
          label={
            cellValue === 'archived'
              ? 'Archived music is not available to streamers'
              : null
          }
          placement='left'
        >
          <Badge
            textTransform={'capitalize'}
            colorScheme={cellValue === 'archived' ? 'red' : 'green'}
          >
            {cellValue || 'Available'}
          </Badge>
        </Tooltip>
      )
    },
    {
      renderCell: ({ rowData, rowIndex, cellValue, onCellValueUpdate }) => (
        <IconButton
          onClick={() => openEditModal(rowData)}
          size='sm'
          icon={<GoPencil />}
          border={'1px dashed gray'}
          isLoading={rowData.isBusy}
        />
      ),
      columnProps: {
        width: '40px'
      }
    },
    {
      renderCell: ({ rowData, rowIndex, cellValue, onCellValueUpdate }) => (
        <Tooltip
          hasArrow
          borderRadius={'md'}
          px='3'
          py='2'
          fontSize={'12px'}
          fontWeight={'semibold'}
          lineHeight={'1rem'}
          bg={rowData.status === 'archived' ? 'green' : 'gray.700'}
          label={
            rowData.status === 'archived' ? 'Make visible' : 'Archive this'
          }
        >
          <IconButton
            size='sm'
            icon={rowData.status === 'archived' ? <GoEye /> : <GoTrash />}
            border={'1px dashed gray'}
            onClick={() => archiveSong(rowData)}
            isLoading={rowData.isBusy}
          />
        </Tooltip>
      ),
      columnProps: {
        width: '40px'
      }
    }
  ]

  useEffect(() => {
    const fetch = async () => {
      console.log('Fetching music...')
      const allSongs = await firebaseContext.getDocument({
        path: `profile/${userId}/songs`
      })
      const getSubscriptions = FirebaseFunctions.httpsCallable(
        firebaseContext.functions,
        'getSubscriptions'
      )
      try {
        const result = await getSubscriptions()
        dispatch(setAllSubscriptions(result.data))
        dispatch(setAllMusic(allSongs))
      } catch (ex) {
        console.warn('CloudFunction error:', ex)
      }
    }
    if (!musicState || musicState?.length <= 0) fetch()
  }, [])

  async function archiveSong (data) {
    dispatch(setMusic({ ...data, isBusy: true }))
    const _data = {
      ...data,
      status:
        data.status === 'available' || !data.status ? 'archived' : 'available'
    }
    await firebaseContext.addDocument({
      path: `profile/${userId}/songs/${data.id}`,
      data: _data
    })
    dispatch(setMusic({ ..._data, isBusy: false }))
  }

  function openModal () {
    setMediaData(initState)
    newItemModalRef.current?.triggerModal()
  }

  function openEditModal (rowData) {
    setEditMode(true)
    setMediaData(rowData)
    newItemModalRef.current?.triggerModal()
  }

  function updateState (key, value) {
    setMediaData(prev => ({ ...prev, [key]: value }))
  }

  async function createUpdateMedia () {
    if (!isFormValid()) {
      console.warn('Invalid form...')
      toast({
        title: 'Something went wrong',
        description: 'Please fill in all fields',
        status: 'error',
        isClosable: true,
        duration: 2000
      })
      return
    }
    const result = await firebaseContext.chainOperations(
      () =>
        Promise.all([
          firebaseContext.uploadFile({
            path: 'songs',
            data: musicUploadBtnRef.current?.getFileList()[0]
          }),
          firebaseContext.uploadFile({
            path: 'photos',
            data: pictureUploadBtnRef.current?.getFileList()[0]
          })
        ]),
      prev =>
        firebaseContext.addDocument({
          path: mediaData.id
            ? `profile/${userId}/songs/${mediaData.id}`
            : `profile/${userId}/songs`,
          data: {
            ...mediaData,
            baseUrl: prev[0].url.length <= 0 ? mediaData.baseUrl : prev[0].url,
            imageUrl: prev[1].url.length <= 0 ? mediaData.imageUrl : prev[1].url
          }
        })
    )

    console.log('Result is:', result)
    dispatch(setMusic(result))
    setEditMode(false)
    newItemModalRef.current?.triggerModal()
  }

  function isFormValid () {
    const _match = SharedComponents.FunctionExtensions.matchObjects(
      initState,
      mediaData,
      {
        ignoreKeys: ['createdOn', 'albumId', 'priceId']
      }
    )
    let _empty = false
    const isEmpty = SharedComponents.FunctionExtensions.hasEmptyValue(
      mediaData,
      ['baseUrl', 'imageUrl', 'albumId', 'priceId']
    )
    if (mediaData.type === 'album') {
      _empty = isEmpty || (!editMode && !profilePictureFile)
    } else {
      _empty =
        isEmpty ||
        (!editMode && !mediaFile) ||
        (!editMode && !profilePictureFile)
    }
    return !_match && !_empty
  }

  function playMusic (data) {
    playerRef.current?.play(data.baseUrl, data.imageUrl, data.name)
  }

  function showAnalytics (rowData) {
    analyticsViewer.current?.show(rowData.id)
  }

  function getMediaSubscription () {
    if (Array.isArray(mediaData.priceId)) {
      const _plans = subscriptionState?.filter(
        x => x.active && mediaData.priceId.some(y => y.id === x.id)
      )
      return _plans.map(single => ({
        label: `${single.product.name} - $${single.unit_amount / 100} ${
          single.recurring ? `per ${single.recurring.interval}` : 'One-Time'
        }`,
        value: single.id
      }))
    } else if (mediaData.priceId) {
      const _plan = subscriptionState.find(x => x.id === mediaData.priceId)

      return {
        label: `${_plan.product.name} - $${_plan.unit_amount / 100} ${
          _plan.recurring ? `per ${_plan.recurring.interval}` : 'One-Time'
        }`,
        value: _plan.id
      }
    }
  }

  function updateStatePricing (key, value) {
    if (key === 'useAlbumPricingPlan') {
      setMediaData(prev => ({
        ...prev,
        priceId: value ? null : prev.priceId,
        useAlbumPricingPlan: value
      }))
    } else if (key === 'priceId') {
      const constructedPriceId = value?.map(_priceId => {
        if (
          subscriptionState?.some(
            sub_single => sub_single.id === _priceId && sub_single.recurring
          )
        ) {
          return {
            id: _priceId,
            type: 'recurring'
          }
        } else
          return {
            id: _priceId,
            type: 'onetime'
          }
      })
      setMediaData(prev => ({
        ...prev,
        useAlbumPricingPlan: false,
        priceId: constructedPriceId
      }))
    }
  }

  return (
    <PageLayout>
      <MusicComponent.AnalyticsViewer ref={analyticsViewer} />
      <Box pos='absolute' right={'10px'} bottom={'30px'} zIndex={'99'}>
        <MusicComponent.SimpleAudioPlayer ref={playerRef} />
      </Box>
      <SharedComponents.AppComponent.CreateBtn
        label={'Upload Music'}
        onClick={openModal}
      />
      <SharedComponents.AppComponent.ExtendedTable
        data={musicState}
        columnDef={ColumnDef}
        // onSort={sortMusic}
      />
      <SharedComponents.AppComponent.Modal
        ref={newItemModalRef}
        onProceed={createUpdateMedia}
        isProceedBusy={firebaseContext.isBusy}
        isProceedDisabled={!isFormValid()}
        size='md'
      >
        <VStack align={'flex-start'} w='full' spacing={'0'}>
          <Text fontSize={'14px'} fontWeight={'600'}>
            I'm uploading a
          </Text>
          <RadioGroup
            defaultValue={mediaData.type}
            onChange={val => updateState('type', val)}
          >
            <HStack>
              <Radio value='music'>Music</Radio>
              <Radio value='album'>Album</Radio>
            </HStack>
          </RadioGroup>
        </VStack>
        {mediaData.type === 'music' && (
          <SharedComponents.AppComponent.UploadBtn
            ref={musicUploadBtnRef}
            label={'Upload Music'}
            fileTypes={'audio/*'}
            onValue={files => setMediaFile(files[0])}
          />
        )}
        <SharedComponents.AppComponent.UploadBtn
          ref={pictureUploadBtnRef}
          label={`Upload ${mediaData.type} Cover`}
          fileTypes={'image/*'}
          multiple={false}
          onValue={files => setProfilePictureFile(files[0])}
        />
        <SharedComponents.AppComponent.LabeledInput
          value={mediaData.name}
          label={`${mediaData.type} Name`}
          onChange={val => updateState('name', val)}
        />
        {mediaData.type === 'music' && (
          <ChakraSelect
            placeholder='Assign to album...'
            onChange={e => updateState('albumId', e.target.value)}
            defaultValue={mediaData.albumId}
          >
            {musicState
              ?.filter(x => x.type === 'album')
              .map((x, i) => (
                <option key={`album_selection_${x.name}_${i}`} value={x.id}>
                  {x.name} - from {moment(x.createdOn).format('MMM Do YYYY')}
                </option>
              ))}
          </ChakraSelect>
        )}
        <VStack align={'flex-start'} w='full'>
          <Text fontSize={'14px'} fontWeight={'600'}>
            Monetization Plan
          </Text>
          {mediaData.type === 'music' &&
            mediaData.albumId &&
            mediaData.albumId?.length > 0 && (
              <Checkbox
                isChecked={mediaData.useAlbumPricingPlan}
                onChange={e =>
                  updateStatePricing('useAlbumPricingPlan', e.target.checked)
                }
              >
                <Text fontSize={'14px'} fontWeight={'600'}>
                  Use Album Monetization Plan
                </Text>
              </Checkbox>
            )}
          {!mediaData.useAlbumPricingPlan && (
            <Select
              styles={{
                container: (baseStyles, state) => ({
                  ...baseStyles,
                  width: '100%'
                })
              }}
              placeholder='Assign plan...'
              onChange={value =>
                updateStatePricing(
                  'priceId',
                  value.map(x => x.value)
                )
              }
              value={getMediaSubscription()}
              isMulti
              options={subscriptionState
                ?.filter(x => x.active)
                ?.map(x => ({
                  label: `${x.product.name} - $${x.unit_amount / 100} ${
                    x.recurring ? `per ${x.recurring.interval}` : 'One-Time'
                  }`,
                  value: x.id
                }))}
            />
          )}
          {(!getMediaSubscription() || getMediaSubscription().length <= 0) && (
            <Badge
              colorScheme='yellow'
              textTransform={'none'}
              w='full'
              style={{
                textWrap: 'wrap'
              }}
              px='3'
              py='2'
            >
              {`If no monetization plan is assigned, streamers will be able to stream this ${
                mediaData.type
              } for 'Free'. ${
                mediaData.useAlbumPricingPlan
                  ? 'You can ignore this message if you have assigned a monetization plan to the associated album.'
                  : ''
              }`}
            </Badge>
          )}
        </VStack>
      </SharedComponents.AppComponent.Modal>
    </PageLayout>
  )
}
