import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  id: undefined
}

export const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    setUser: (state, action) => {
      console.log('Setting user:', action.payload)
      return action.payload
    },
    setUserProps: (state, action) => {
      console.log('Setting user props..')
      state[action.payload.key] = action.payload.value
    }
  }
})

// Action creators are generated for each case reducer function
export const { setUser, setUserProps } = userSlice.actions

export default userSlice.reducer
