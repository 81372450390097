import Home from './home'
import Monetization from './monetization'
import MyMusic from './mymusic'
import Profile from './profile'
import TicketMaster from './ticketMaster'
import Users from './users'

export default {
  Home,
  Users,
  MyMusic,
  Monetization,
  Profile,
  TicketMaster
}
