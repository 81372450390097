import {
  Box,
  Button,
  Checkbox,
  HStack,
  Table,
  TableContainer,
  Tbody,
  Td,
  Text,
  Tfoot,
  Th,
  Thead,
  Tooltip,
  Tr,
  VStack
} from '@chakra-ui/react'
import PageLayout from '../pageLayout'
import moment from 'moment/moment'
import { useEffect, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import SharedComponents from 'shared-components'
import { setUser } from '../../../redux/slices/userSlice'
import * as FirebaseFunctions from 'firebase/functions'

export default () => {
  const user = useSelector(state => state.user)
  const dispatch = useDispatch()
  const [shallowUser, setShallowUserState] = useState(user)
  const [formImage, setFormImage] = useState({
    profile: null,
    cover: null
  })
  const [passwordState, setPasswordState] = useState({
    modalRef: useRef(),
    password: '',
    rePassword: '',
    isBusy: false
  })
  const firebaseContext = SharedComponents.Firebase.context()

  async function performPasswordReset () {
    updatePasswordState('isBusy', true)
    const res = FirebaseFunctions.httpsCallable(
      firebaseContext.functions,
      'resetPasswordFromAdmin'
    )
    try {
      const result = await res({
        password: passwordState.password
      })
      if (result.data) passwordState.modalRef.current?.triggerModal()
    } catch (ex) {
      console.log('CloudFunction error is:', ex)
    }
    updatePasswordState('isBusy', false)
  }

  async function updateProfile () {
    const data = { ...shallowUser }
    const result = await firebaseContext.chainOperations(
      () =>
        Promise.all([
          firebaseContext.uploadFile({
            path: 'photos',
            data: formImage.profile
          }),
          firebaseContext.uploadFile({
            path: 'photos',
            data: formImage.cover
          })
        ]),
      prev => {
        if (!SharedComponents.FunctionExtensions.hasEmptyValue(prev)) {
          return firebaseContext.addDocument({
            path: `profile/${user.id}`,
            data: {
              ...shallowUser,
              photoUrl: prev[0].url,
              coverUrl: prev[1].url
            }
          })
        } else {
          return firebaseContext.addDocument({
            path: `profile/${user.id}`,
            data: shallowUser
          })
        }
      }
    )
    console.log('Result is:', result)
    if (!result?.error) dispatch(setUser(result))

    // const result = await firebaseContext.addDocument({
    //   path: `profile/${user.id}`,
    //   data: data
    // })
  }

  function updateState (key, value) {
    SharedComponents.FunctionExtensions.updateNestedState({
      setStateFunction: setShallowUserState,
      path: key,
      value
    })
  }

  function updateImage (files, key) {
    if (files) {
      setFormImage(prev => ({
        ...prev,
        [key]: files[0]
      }))
    }
  }

  function updatePasswordState (key, value) {
    setPasswordState(prev => ({ ...prev, [key]: value }))
  }

  function initPasswordReset () {
    passwordState.modalRef.current?.triggerModal()
  }

  function isPasswordFormValid () {
    return (
      passwordState.password.length > 0 &&
      passwordState.rePassword.length > 0 &&
      passwordState.password === passwordState.rePassword
    )
  }

  return (
    <PageLayout>
      <VStack
        alignSelf={'center'}
        w='40%'
        alignContent={'center'}
        pos='relative'
        // pt='20'
        spacing={'4'}
      >
        <SharedComponents.AppComponent.LazyImage
          h='200px'
          w='full'
          src={
            formImage.cover
              ? URL.createObjectURL(formImage.cover)
              : user.coverUrl
          }
          borderRadius={'10px'}
          objectFit={'cover'}
          pos='absolute'
          zIndex={'0'}
          left='0'
          right={'0'}
        />

        <SharedComponents.AppComponent.LazyImage
          h='150px'
          w='150px'
          src={
            formImage.profile
              ? URL.createObjectURL(formImage.profile)
              : user.photoUrl
          }
          borderRadius={'full'}
          objectFit={'cover'}
          mt={'20'}
          boxShadow={'0px 0px 15px 0px rgba(0, 0, 0, 0.3)'}
          zIndex={'2'}
          pos='relative'
        />
        <HStack>
          <SharedComponents.AppComponent.UploadBtn
            label='Upload Profile Picture'
            chakraButtonProps={{
              alignSelf: 'center'
            }}
            onValue={files => updateImage(files, 'profile')}
          />
          <Tooltip
            hasArrow
            placement='bottom'
            px='3'
            py='2'
            borderRadius={'md'}
            label='Your cover photo will appear on the streaming app, including the login screen and others.'
          >
            <Box>
              <SharedComponents.AppComponent.UploadBtn
                label='Upload Cover Photo'
                chakraButtonProps={{
                  alignSelf: 'center'
                }}
                onValue={files => updateImage(files, 'cover')}
              />
            </Box>
          </Tooltip>
        </HStack>
        <SharedComponents.AppComponent.LabeledInput
          label={'Display Name'}
          value={shallowUser.name}
          onChange={value => updateState('name', value)}
        />
        <SharedComponents.AppComponent.LabeledInput
          label={'Contact Email'}
          value={shallowUser.contactEmail}
          onChange={value => updateState('contactEmail', value)}
        />
        {/* <Button onClick={testPasswordReset}>Test</Button> */}
        <Button
          w='full'
          size='md'
          colorScheme='green'
          isLoading={firebaseContext.isBusy}
          onClick={updateProfile}
        >
          Update Profile
        </Button>
        <Button
          w='full'
          size='md'
          colorScheme='red'
          onClick={initPasswordReset}
        >
          Reset Password
        </Button>
        <SharedComponents.AppComponent.Modal
          ref={passwordState.modalRef}
          onProceed={performPasswordReset}
          isProceedBusy={passwordState.isBusy}
          isProceedDisabled={!isPasswordFormValid()}
          size='md'
          proceedBtnLabel='Update Password'
        >
          <SharedComponents.AppComponent.LabeledInput
            label={'New Password'}
            value={passwordState.password}
            onChange={value => updatePasswordState('password', value)}
            inputProps={{
              type: 'password'
            }}
          />
          <SharedComponents.AppComponent.LabeledInput
            label={'Re-enter New Password'}
            value={passwordState.rePassword}
            onChange={value => updatePasswordState('rePassword', value)}
            inputProps={{
              type: 'password'
            }}
          />
        </SharedComponents.AppComponent.Modal>
      </VStack>
    </PageLayout>
  )
}
