import { createSlice } from '@reduxjs/toolkit'

const initialState = []

export const musicSlice = createSlice({
  name: 'music',
  initialState,
  reducers: {
    setAllMusic: (state, action) => {
      return action.payload
    },
    setMusic: (state, action) => {
      if (state && state.length > 0) {
        const spread = [...state]
        const _music = spread.find(x => x.id === action.payload.id)

        // Found existing data
        if (_music) {
          const _musicIndex = spread.findIndex(x => x.id === action.payload.id)
          spread[_musicIndex] = { ..._music, ...action.payload }
          return spread
        } else {
          return [...spread, action.payload]
        }
      } else {
        return [action.payload]
      }
    }
  }
})

// Action creators are generated for each case reducer function
export const { setAllMusic, setMusic } = musicSlice.actions

export default musicSlice.reducer
