import {
  Badge,
  Button,
  Checkbox,
  HStack,
  IconButton,
  Image,
  Radio,
  RadioGroup,
  Select,
  Text,
  Tooltip,
  useToast,
  VStack
} from '@chakra-ui/react'
import PageLayout from '../pageLayout'
import { useEffect, useRef, useState } from 'react'
import SharedComponents from 'shared-components'
import { useDispatch, useSelector } from 'react-redux'
import { setAllMusic, setMusic } from '../../../redux/slices/musicSlice'
import moment from 'moment'
import { GoEye, GoPencil, GoPlay, GoTrash } from 'react-icons/go'
import { LuMusic2 } from 'react-icons/lu'
import { BsMusicNoteList } from 'react-icons/bs'
import { FaPlay } from 'react-icons/fa6'
import * as FirebaseFunctions from 'firebase/functions'
import {
  setAllSubscriptions,
  setSubscription
} from '../../../redux/slices/subscriptionSlice'

const SAMPLE_DATA = {
  name: 'Album 1',
  createdOn: new Date(),
  type: 'album',
  streamCount: 1,
  imageUrl: '',
  baseUrl: '',
  price: 0,
  chargeType: 0
}

export default () => {
  const newItemModalRef = useRef()
  const subscriptionState = useSelector(state => state.subscription)
  const initState = {
    unit_amount: 1000,
    recurring: {
      interval: 'month'
    },
    product_data: {
      name: ''
    }
  }
  const [subscriptionData, setSubscriptionData] = useState(initState)
  const [isBusy, setIsBusy] = useState(false)
  const [editMode, setEditMode] = useState(false)
  const dispatch = useDispatch()
  const firebaseContext = SharedComponents.Firebase.context()
  const stripeAccountId = useSelector(state => state.user.stripeAccountId)
  const toast = useToast()
  const ColumnDef = [
    {
      label: 'Name',
      key: 'product.name'
    },
    {
      label: 'Charge Type',
      key: 'recurring.interval',
      renderCell: ({ cellValue }) => (
        <Text>{cellValue ? `Every ${cellValue}` : `One-Time`}</Text>
      )
    },
    {
      label: 'Price ($)',
      key: 'unit_amount',
      renderCell: ({ rowData, cellValue }) => <Text>$ {cellValue / 100}</Text>
    },
    {
      label: 'Status',
      key: 'active',
      renderCell: ({ rowData, rowIndex, cellValue, onCellValueUpdate }) => (
        <Tooltip
          hasArrow
          borderRadius={'md'}
          px='3'
          py='2'
          fontSize={'12px'}
          fontWeight={'semibold'}
          lineHeight={'1rem'}
          w='300px'
          label={
            cellValue !== true && (
              <>
                Deactivated plans are not visible to your customers. Also,
                customers who initially subscribed to this plan, will no longer
                be charged for their subscription and their subscription will be
                cancelled at the end of their biilling cycle
              </>
            )
          }
          placement='left'
        >
          <Badge
            textTransform={'capitalize'}
            colorScheme={cellValue ? 'green' : 'red'}
          >
            {cellValue ? 'Active' : 'Inactive'}
          </Badge>
        </Tooltip>
      )
    },
    {
      renderCell: ({ rowData, cellValue }) => (
        <Tooltip
          hasArrow
          borderRadius={'md'}
          px='3'
          py='2'
          fontSize={'12px'}
          fontWeight={'semibold'}
          lineHeight={'1rem'}
          bg={!rowData.active ? 'green' : 'gray.700'}
          label={!rowData.active ? 'Activate Plan' : 'Deactivate Plan'}
        >
          <IconButton
            size='sm'
            icon={!rowData.active ? <GoEye /> : <GoTrash />}
            border={'1px dashed gray'}
            onClick={() => changeStatus(rowData)}
            isLoading={rowData.isBusy}
          />
        </Tooltip>
      ),
      columnProps: {
        width: '40px'
      }
    }
  ]

  function openModal () {
    // createSubscription()
    setSubscriptionData(initState)
    newItemModalRef.current?.triggerModal()
  }

  function updateState (key, value) {
    if (key === 'recurring.interval' && value === 'onetime') {
      setSubscriptionData(prev => {
        const _shallow = { ...prev }
        delete _shallow.recurring
        return _shallow
      })
    } else {
      SharedComponents.FunctionExtensions.updateNestedState({
        setStateFunction: setSubscriptionData,
        path: key,
        value:
          key === 'recurring.interval' && value === 'onetime' ? null : value
      })
    }
  }

  useEffect(() => {
    fetch()
  }, [])

  const fetch = async () => {
    const getSubscriptions = FirebaseFunctions.httpsCallable(
      firebaseContext.functions,
      'getSubscriptions'
    )
    try {
      const result = await getSubscriptions()
      console.log('Subscriptions are:', result.data)
      dispatch(setAllSubscriptions(result.data))
    } catch (ex) {
      console.warn('CloudFunction error:', ex)
    }
  }

  async function createSubscription () {
    setIsBusy(true)
    const _subscription = FirebaseFunctions.httpsCallable(
      firebaseContext.functions,
      'createSubscription'
    )
    try {
      const result = await _subscription({
        ...subscriptionData
        // metadata: { name: subscriptionData.product_data.name }
      })
      if (result.data) {
        newItemModalRef.current?.triggerModal()
        dispatch(setSubscription(result.data))
        console.log('New subscription created:', result.data)
      } else {
        toast({
          title: 'Could not create plan',
          description: 'Something went wrong. Please try again later',
          status: 'error',
          isClosable: true
        })
      }
    } catch (ex) {
      console.warn('CloudFunction error:', ex)
    }
    setIsBusy(false)
  }

  function isFormValid () {
    const _match = SharedComponents.FunctionExtensions.matchObjects(
      initState,
      subscriptionData
    )
    const isEmpty = SharedComponents.FunctionExtensions.hasEmptyValue(
      subscriptionData,
      [
        'custom_unit_amount',
        'lookup_key',
        'nickname',
        'tiers_mode',
        'recurring',
        'transform_quantity'
      ]
    )
    return !_match && !isEmpty
  }

  async function changeStatus (data) {
    dispatch(setSubscription({ ...data, isBusy: true }))
    const _subscription = FirebaseFunctions.httpsCallable(
      firebaseContext.functions,
      'updateSubscription'
    )
    try {
      const result = await _subscription({
        id: data.id,
        params: { active: !data.active }
      })
      // console.log('Actual data is:', data)
      dispatch(
        setSubscription({
          ...data,
          active: result.data.active,
          isBusy: false
        })
      )
      // console.log('Subscription updated:', result.data)
    } catch (ex) {
      console.warn('CloudFunction error:', ex)
      dispatch(setSubscription({ ...data, isBusy: false }))
    }
  }

  return (
    <PageLayout>
      <SharedComponents.AppComponent.CreateBtn
        label={'Create Plan'}
        onClick={openModal}
      />
      <SharedComponents.AppComponent.ExtendedTable
        data={subscriptionState}
        columnDef={ColumnDef}
      />
      <SharedComponents.AppComponent.Modal
        ref={newItemModalRef}
        onProceed={createSubscription}
        isProceedBusy={isBusy}
        isProceedDisabled={!isFormValid()}
        size='md'
      >
        <VStack align={'flex-start'} w='full'>
          <SharedComponents.AppComponent.LabeledInput
            value={subscriptionData.product_data.name}
            label={`Plan Name`}
            onChange={val => updateState('product_data.name', val)}
          />
          <Text fontSize={'14px'} fontWeight={'600'}>
            Monetization Type
          </Text>
          <RadioGroup
            defaultValue={subscriptionData.recurring?.interval || 'onetime'}
            onChange={val => updateState('recurring.interval', val)}
          >
            <HStack w='full' flexWrap={'wrap'}>
              <Radio value='onetime'>One time Payment</Radio>
              <Radio value='month'>Monthly Payment</Radio>
            </HStack>
          </RadioGroup>
        </VStack>
        <SharedComponents.AppComponent.LabeledInput
          label={'Amount to charge (in USD)'}
          inputProps={{
            type: 'number'
          }}
          onChange={val => updateState('unit_amount', val * 100)}
          value={subscriptionData.unit_amount / 100}
        />
        {/* <SharedComponents.AppComponent.LabeledInput
          label={'Maximum number of streams'}
          inputProps={{
            type: 'number'
          }}
          onChange={val => updateState('price', val)}
          value={subscriptionData.price}
        />
        <Badge
          colorScheme={'yellow'}
          textTransform={'normal'}
          w='full'
          style={{ textWrap: 'wrap' }}
        >
          The maximum number of times a user can stream your music, over the
          selected period of time (per week/month/year)
        </Badge> */}
      </SharedComponents.AppComponent.Modal>
    </PageLayout>
  )
}
