import { Button, Text, useToast, VStack } from '@chakra-ui/react'
import AuthLayout from './layout'
import { useDispatch, useSelector } from 'react-redux'
import { setUser } from '../../redux/slices/userSlice'
import { FaArrowRight } from 'react-icons/fa6'
import SharedComponents from 'shared-components'
import { signInWithEmailAndPassword } from 'firebase/auth'
import { useState } from 'react'
import { Form, Formik } from 'formik'

export default () => {
  const dispatch = useDispatch()
  const firebaseContext = SharedComponents.Firebase.context()
  const appState = useSelector(state => state.app)
  const toast = useToast()

  async function triggerLogin ({ email, password }) {
    // setFormData(prev => ({ ...prev, isBusy: true }))
    try {
      const signInResult = await signInWithEmailAndPassword(
        firebaseContext.auth,
        email,
        password
      )
      const user = signInResult.user
      if (user) {
        if (user.uid === appState.adminAppId) {
          const profile = await firebaseContext.getDocument({
            path: `profile/${user.uid}`
          })
          if (profile) {
            dispatch(
              setUser({
                id: user.uid,
                contactEmail: email,
                ...profile
              })
            )
          } else {
            toast({
              title: 'Account not set up',
              description: 'Please contact the administrator.',
              status: 'error',
              isClosable: true
            })
          }
        } else {
          toast({
            title: 'Not correct admin id',
            description:
              'You are logging in with wrong credentials. Try again.',
            status: 'error',
            isClosable: true
          })
        }
      }
    } catch (ex) {
      toast({
        title: 'Something went wrong',
        description: 'Please check your information again',
        status: 'error',
        isClosable: true
      })
      console.log(ex)
    }
    // setFormData(prev => ({ ...prev, isBusy: false }))
  }

  return (
    <VStack w='full' align={'flex-start'}>
      <Text fontSize={'22px'} fontWeight={'600'}>
        Sign in to your admin account
      </Text>
      <Formik
        initialValues={{
          email: '',
          password: ''
        }}
        onSubmit={async values => {
          await triggerLogin(values)
        }}
      >
        {({ handleSubmit, values, isSubmitting, setFieldValue }) => (
          <VStack w='full'>
            <SharedComponents.AppComponent.LabeledInput
              label='Email'
              value={values.email}
              onChange={val => setFieldValue('email', val)}
            />
            <SharedComponents.AppComponent.LabeledInput
              label='Password'
              inputProps={{
                type: 'password'
              }}
              value={values.password}
              onChange={val => setFieldValue('password', val)}
            />
            <Button
              type='submit'
              boxShadow={'md'}
              colorScheme='blue'
              w='full'
              border={'1px solid #e4e4e4'}
              rightIcon={<FaArrowRight />}
              onClick={handleSubmit}
              isLoading={isSubmitting}
              loadingText='Logging in...'
            >
              Sign In
            </Button>
          </VStack>
        )}
      </Formik>
    </VStack>
  )
}
