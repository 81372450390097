import {
  Box,
  Grid,
  HStack,
  IconButton,
  Image,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Spinner,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  Text,
  VStack
} from '@chakra-ui/react'
import { FaPause, FaPlay, FaSalesforce, FaX } from 'react-icons/fa6'
import './components.css'
import { forwardRef, useEffect, useImperativeHandle, useState } from 'react'
import SharedComponents, {
  FirestorePackageExport as FirestorePackage
} from 'shared-components'

import { Howl, Howler } from 'howler'
import { useSelector } from 'react-redux'

const SimpleAudioPlayer = forwardRef(({}, ref) => {
  const [musicData, setMusicData] = useState()
  const [sound, setSound] = useState()
  const [soundId, setSoundId] = useState()

  useEffect(() => {
    const scrollText = document.getElementById('scroll-text')
    const textWidth = scrollText.scrollWidth
    scrollText.style.setProperty('--text-width', `${textWidth}px`)
    // console.log(scrollWidth, textWidth)
    // if (textWidth <= scrollWidth) {
    //   console.log('Removing anim..')
    //   scrollText.style.animation = null
    // }
  }, [musicData])

  useImperativeHandle(ref, () => ({
    play (songUrl, imageUrl, name) {
      setMusicData({ songUrl, imageUrl, name })
      const sound = new Howl({
        src: songUrl,
        onload: () =>
          setMusicData(prev => ({ ...prev, isLoaded: true, isPlaying: true }))
      })
      const soundId = sound.play()
      setSound(sound)
      setSoundId(soundId)
    }
  }))

  useEffect(() => {
    if (!musicData) sound?.stop()
  }, [musicData])

  function playPause () {
    setMusicData(prev => ({ ...prev, isPlaying: !prev.isPlaying }))
    if (musicData?.isPlaying) sound?.pause()
    else {
      sound?.play(soundId)
    }
  }
  return (
    <HStack w='max-content' display={musicData ? 'flex' : 'none'}>
      <HStack bg='gray.800' px='1' py='1' borderRadius={'full'} spacing={'3'}>
        <SharedComponents.AppComponent.LazyImage
          h='35px'
          w='35px'
          src={musicData?.imageUrl}
          borderRadius={'full'}
          objectFit={'cover'}
        />
        <IconButton
          icon={
            musicData?.isPlaying ? <FaPause size='15' /> : <FaPlay size='15' />
          }
          h='35px'
          w='35px'
          p='0'
          size='sm'
          borderRadius={'full'}
          colorScheme='black'
          _hover={{
            bg: 'white',
            color: 'black'
          }}
          isLoading={!musicData?.isLoaded}
          isDisabled={!musicData?.isLoaded}
          onClick={playPause}
        />
        <Box
          pos='relative'
          w='150px'
          color='white'
          h='20px'
          overflow={'hidden'}
          id='scroll-container'
        >
          <Box id='scroll-text' pos='absolute'>
            <Text
              h='max-content'
              fontSize={'14px'}
              fontWeight={'bold'}
              style={{
                textWrap: 'nowrap'
              }}
            >
              {musicData?.name}
            </Text>
          </Box>
        </Box>
      </HStack>
      <IconButton
        size='md'
        h='44px'
        w='45px'
        icon={<FaX />}
        borderRadius={'full'}
        bg='gray.800'
        color='white'
        _hover={{
          bg: 'red',
          color: 'white'
        }}
        onClick={() => setMusicData(null)}
      />
    </HStack>
  )
})

const AnalyticsViewer = forwardRef(({}, ref) => {
  const firebaseContext = SharedComponents.Firebase.context()
  const userId = useSelector(state => state.user.id)
  const [isOpen, setIsOpen] = useState(false)
  const [isBusy, setIsBusy] = useState(true)
  const [analytics, setAnalytics] = useState({
    streamCount: null,
    streamAvg: null,
    topLocations: null,
    topListeners: null,
    salesCount: null,
    salesSum: null
  })
  // Total Sales
  // Total Streams
  // Total Revenue
  // Most Played

  useImperativeHandle(ref, () => ({
    async show (musicId) {
      setIsOpen(true)
      try {
        setIsBusy(true)
        //#region Get stream details
        const streamsCollectionRef = {
          parent: FirestorePackage.collection(
            firebaseContext.database,
            `profile/${userId}/songs/${musicId}/streams`
          ),
          overview_location: FirestorePackage.collection(
            firebaseContext.database,
            `profile/${userId}/songs/${musicId}/streams-overview-location`
          ),
          overview_listeners: FirestorePackage.collection(
            firebaseContext.database,
            `profile/${userId}/songs/${musicId}/streams-overview-listeners`
          )
        }
        // const streamCount = (
        //   await FirestorePackage.getCountFromServer(streamsCollectionRef)
        // ).data().count
        const snapshot_streams = await FirestorePackage.getAggregateFromServer(
          streamsCollectionRef.parent,
          {
            avgDuration: FirestorePackage.average('duration'),
            count: FirestorePackage.count()
          }
        )
        const snapshot_overviewLocations = await FirestorePackage.getDocs(
          FirestorePackage.query(
            streamsCollectionRef.overview_location,
            FirestorePackage.orderBy('streamCount', 'desc'),
            FirestorePackage.limit(5)
          )
        )

        const snapshot_overviewListeners = await FirestorePackage.getDocs(
          FirestorePackage.query(
            streamsCollectionRef.overview_listeners,
            FirestorePackage.orderBy('streamCount', 'desc'),
            FirestorePackage.limit(5)
          )
        )
        console.log(
          'Stream count:',
          snapshot_streams.data().count,
          ' and average:',
          snapshot_streams.data().avgDuration
        )
        const top_locations = []
        const top_listeners = []
        snapshot_overviewLocations.forEach(x => top_locations.push(x.data()))
        snapshot_overviewListeners.forEach(x => top_listeners.push(x.data()))
        console.log('Top Listeners:', top_listeners)
        console.log('Top Locations:', top_locations)
        //#endregion

        const snapshot_purchases =
          await FirestorePackage.getAggregateFromServer(
            FirestorePackage.collection(
              firebaseContext.database,
              `profile/${userId}/songs/${musicId}/purchases`
            ),
            {
              amount_total: FirestorePackage.sum('amount_total'),
              count: FirestorePackage.count()
            }
          )
        console.log(
          'Total purchases:',
          snapshot_purchases.data().count,
          ' and amount:',
          snapshot_purchases.data().amount_total
        )

        setAnalytics({
          streamCount: snapshot_streams.data().count,
          streamAvg: snapshot_streams.data().avgDuration,
          salesCount: snapshot_purchases.data().count,
          salesSum: snapshot_purchases.data().amount_total,
          topListeners: top_listeners,
          topLocations: top_locations
        })
      } catch (ex) {
        console.log('Analytics error is:', ex)
        setAnalytics({
          error:
            'Could not fetch analytics. This might be an issue with the application!'
        })
      }
      setIsBusy(false)
    }
  }))

  const LocationColumnDef = [
    {
      key: 'city',
      label: 'City'
    },
    {
      key: 'country',
      label: 'Country'
    },
    {
      key: 'streamCount',
      label: 'Total Streams'
    }
  ]
  const ListenerColumnDef = [
    {
      key: 'name',
      label: 'Name'
    },
    {
      key: 'email',
      label: 'Email'
    },
    {
      key: 'streamCount',
      label: 'Total Streams'
    }
  ]

  return (
    <Modal
      isOpen={isOpen}
      onClose={() => setIsOpen(false)}
      size='xl'
      closeOnOverlayClick={false}
      closeOnEsc={false}
    >
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Song Analytics</ModalHeader>
        <ModalCloseButton />
        <ModalBody pb='5'>
          {isBusy && <Spinner />}
          {!isBusy && (
            <>
              <Grid
                gridTemplateRows={'repeat(2, auto)'}
                gridTemplateColumns={'repeat(2, 1fr)'}
                gridRowGap={'10px'}
                gridColumnGap={'10px'}
              >
                <QuickViewItem
                  title={'Total Streams'}
                  value={analytics.streamCount}
                />
                <QuickViewItem
                  title={'Avg. Stream Duration'}
                  value={`${analytics.streamAvg}s`}
                />
                <QuickViewItem
                  title={'Total Sales'}
                  value={analytics.salesCount}
                />
                <QuickViewItem
                  title={'Total Revenue'}
                  value={`$${analytics.salesSum}`}
                />
              </Grid>
              <Tabs pt='5' colorScheme='green'>
                <TabList>
                  <Tab fontWeight={'semibold'}>Top Locations</Tab>
                  <Tab fontWeight={'semibold'}>Top Listeners</Tab>
                </TabList>

                <TabPanels>
                  <TabPanel pt='0'>
                    <Text fontSize={'14px'} fontWeight={'semibold'} py='3'>
                      Top 5 locations this music was streamed from
                    </Text>
                    <SharedComponents.AppComponent.ExtendedTable
                      data={analytics.topLocations}
                      columnDef={LocationColumnDef}
                      allowSearch={false}
                    />
                  </TabPanel>
                  <TabPanel pt='0'>
                    <Text fontSize={'14px'} fontWeight={'semibold'} py='3'>
                      Top 5 listeners who streamed this music
                    </Text>
                    <SharedComponents.AppComponent.ExtendedTable
                      data={analytics.topListeners}
                      columnDef={ListenerColumnDef}
                      allowSearch={false}
                    />
                  </TabPanel>
                </TabPanels>
              </Tabs>
            </>
          )}
        </ModalBody>
      </ModalContent>
    </Modal>
  )
})

const QuickViewItem = ({ title, value }) => (
  <VStack
    transition={'all 300ms'}
    w='full'
    bg='#ebebeb'
    borderRadius='md'
    p='2'
    px='4'
    spacing='0'
    align='flex-start'
    border={'1px solid transparent'}
    _hover={{
      border: '1px solid #00ab3f'
    }}
  >
    <Text fontWeight={'semibold'} fontSize={'13px'}>
      {title}
    </Text>
    <Text fontSize={'24px'} fontWeight={'bold'} color='green.600'>
      {value}
    </Text>
  </VStack>
)

export const MusicComponent = {
  SimpleAudioPlayer,
  AnalyticsViewer
}
