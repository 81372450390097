import { createSlice } from '@reduxjs/toolkit'

const initialState = []

const customerSlice = createSlice({
  name: 'custoemrs',
  initialState,
  reducers: {
    setCustomers: (state, action) => {
      return action.payload
    }
  }
})

// Action creators are generated for each case reducer function
export const { setCustomers } = customerSlice.actions

export default customerSlice.reducer
