import { GoHome, GoPackage, GoPerson } from 'react-icons/go'
import LoginScreen from './screens/auth/login'
import SignupScreen from './screens/auth/signUp'
import { GrLayer } from 'react-icons/gr'
import AppPages from './screens/mainApp/pages'
import { MdOutlineAttachMoney } from 'react-icons/md'
import { IoMusicalNoteOutline, IoMusicalNotesOutline } from 'react-icons/io5'
import { FiUserCheck } from 'react-icons/fi'
import { LuUserCog2 } from 'react-icons/lu'
import { GiTicket } from 'react-icons/gi'

const Routes = {
  Auth: {
    screens: {
      Login: {
        path: '/',
        element: <LoginScreen />
      }
    }
  },
  MainApp: {
    screens: {
      // Home: {
      //   label: 'Home',
      //   icon: <GoHome size='17' />,
      //   path: '/home',
      //   element: <AppPages.Home />,
      //   pageTitle: 'Quick overview'
      // },
      Monetization: {
        label: 'Monetization',
        pageTitle: 'Monetization Plans',
        icon: <MdOutlineAttachMoney size='17' />,
        path: '/monetization',
        element: <AppPages.Monetization />
      },
      My_Music: {
        label: 'My Music',
        icon: <IoMusicalNotesOutline size='17' />,
        path: '/my-music',
        element: <AppPages.MyMusic />,
        pageTitle: 'Manage your music and albums'
      },
      Users: {
        label: 'My Listeners',
        icon: <FiUserCheck size='17' />,
        path: '/users',
        element: <AppPages.Users />,
        pageTitle: 'Manage users of platform'
      },
      Profile: {
        label: 'My Profile',
        icon: <LuUserCog2 size='17' />,
        path: '/profile',
        element: <AppPages.Profile />,
        pageTitle: 'Manage your profile'
      },
      Ticketmaster: {
        label: 'Ticket Master',
        icon: <GiTicket />,
        path: '/ticketmaster',
        element: <AppPages.TicketMaster />,
        pageTitle: 'Manage your TicketMaster profile'
      }
    }
  }
}

export default Routes

export function extractNestedRoutes (routeObject) {
  if (routeObject.screens && Object.keys(routeObject.screens).length > 0) {
    const nested_routes = []
    Object.keys(routeObject.screens).map(key => {
      const _routeSingle = routeObject.screens[key]
      nested_routes.push({ ..._routeSingle })
    })
    return nested_routes
  }

  return routeObject
}
