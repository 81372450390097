import { Image, VStack } from '@chakra-ui/react'
import Logo from '../../assets/images/logo-horizontal.png'
import { Outlet } from 'react-router-dom'

export default ({ children }) => {
  return (
    <VStack
      h='full'
      minW='35%'
      maxW='400px'
      pt='12'
      pb='8'
      alignItems={'flex-start'}
      overflow={'visible'}
      justify={'center'}
    >
      <Image w='200px' src={Logo} objectFit={'contain'} mb='4' />
      <VStack
        minH='max-content'
        maxH='100%'
        overflowY='scroll'
        bg='white'
        boxShadow={'0px 0px 25px 0px rgba(0, 21, 74, 0.15)'}
        w='full'
        // border='1px solid #e4e4e4'
        borderRadius={'lg'}
        alignItems={'flex-start'}
        p='48px'
        spacing={'5'}
      >
        <Outlet />
      </VStack>
    </VStack>
  )
}
